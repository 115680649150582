import { useMutation, useQuery } from "@apollo/client"
import React, { useState,useCallback } from "react"
import { PRICING_SUMMARY_PAGE, PRICING_CREATE_PAYMENT_INFO } from "../../components/graphql"
import Layout from "../../components/layout"
import Login from "../../components/login"
import useRazorpay from "react-razorpay";
import "../../assets/css/pricingStyle.css"
import { navigate } from 'gatsby';
import axios from "axios"
import sticky_logo from "../../assets/images/sticky_logo.png";
import { parse } from "path"

function Summary() {
  const summary = useQuery(PRICING_SUMMARY_PAGE)
  const [createPaymentInfo] = useMutation(PRICING_CREATE_PAYMENT_INFO)
  const [open, setOpen] = useState(false)
  const [login, setLogin] = useState(false)
  const [forgotPassword, setForgotPassword] = useState(false)
  const [username, setUsername] = useState(undefined)
  const [userId, setUserID] = useState(undefined)
  const [phoneError, setphoneError] = useState("")
  const [successMessage, setSuccessMessage] = useState("")
  const [errorMessage, setErrorMessage] = useState("")
  const [categoryServiceIndex, setCategoryServiceIndex] = useState(0)
  const [categoryType, setCategoryType] = useState("INDIVIDUALSERVICES")
  const [selectedCurrency, setSelectedCurrency] = useState("USD")
  const [cartListItem, setCartListItem] = useState()
  const [currencySymbol, setCurrencySymbol] = useState()
  const [currencyMode, setCurrencyMode] = useState()
  const Razorpay = useRazorpay();
  var options={};
  var summaryData = summary?.data?.pricingPage
  var summaryCartItems = {}
  var cartItems = ""
  React.useEffect(() => {
    cartItems = sessionStorage.getItem("CARTITEMS")
    if (cartItems !== 'null') {
      setCartListItem(JSON.parse(cartItems))
    }
    else{
      navigate('/pricing')
    }
    return () => {
      sessionStorage.setItem("CARTITEMS", null)
    }
  }, []);

  const currencyPrinter = priceList => {
    var current = priceList?.filter(
      e =>
        e.currencyType?.toString()?.toUpperCase() == cartListItem?.currencyMode
    )[0]
    if (!currencySymbol) {
      setCurrencySymbol(current?.currencySymbol)
      setCurrencyMode(current?.currencyMode)
    }
    return (
      <div>
        {current?.servicePrice == 0 ? "FREE" : current?.servicePrice ?? "FREE"}
        {current?.servicePrice == 0 ? "" : current?.currencySymbol}
      </div>
    )
  }

  const bundleTotalCalculator = () => {
    var total = cartListItem?.cartItem?.reduce(
      (previousValue, currentValue) => {
        return (
          previousValue +
            currentValue?.servicePrice?.filter(
              e =>
                e.currencyType?.toString()?.toUpperCase() ==
                cartListItem?.currencyMode
            )[0]?.servicePrice ?? 0
        )
      },
      0
    )
    if(cartListItem?.isCreatedBundle){
      total = parseFloat(total) - parseFloat(discountCalcualtor())
    }
    return (
      <div>
        {parseFloat(total).toFixed(2)}
        {currencySymbol}
      </div>
    )
  }
  
  const discountCalcualtor = () => {
    const cartItemTemp=JSON.parse(sessionStorage.getItem("CARTITEMS"))
    var total = cartItemTemp?.cartItem?.reduce(
      (previousValue, currentValue) => {
        return (
          previousValue +
            currentValue?.servicePrice?.filter(
              e =>
                e.currencyType?.toString()?.toUpperCase() ==
                cartItemTemp?.currencyMode
            )[0]?.servicePrice ?? 0
        )
      },
      0
    )
    var discountValue= cartItemTemp?.bundleDiscountValue
    var DiscountTemp  = parseFloat(total) >0 ?(((parseFloat(discountValue)/100)*parseFloat(total)).toFixed(2)) :0
    return( DiscountTemp)
  }

  const handlePayment = useCallback(() => {
    var cartItemsTemp=  sessionStorage.getItem("CARTITEMS")
    var currencyModeTemp =""
    var total = 0.0
    var items =""
    if(cartItemsTemp !== 'null'){
      currencyModeTemp =  JSON.parse(cartItemsTemp)?.currencyMode
      if(currencyModeTemp === "EURO"){
        currencyModeTemp = "EUR"
      }
      if(JSON.parse(cartItemsTemp)?.isBundleProducts){
        total = JSON.parse(cartItemsTemp)?.cartItem?.bundlePricing?.filter(
          e =>
            e.currencyType?.toString()?.toUpperCase() ==
            JSON.parse(cartItemsTemp)?.currencyMode
        )[0]?.servicePrice ?? 0
        items = (JSON.parse(cartItemsTemp)?.cartItem?.bundleName +" - "+total+JSON.parse(cartItemsTemp)?.cartItem?.bundlePricing?.filter(
          e =>
            e.currencyType?.toString()?.toUpperCase() ==
            JSON.parse(cartItemsTemp)?.currencyMode
        )[0]?.currencySymbol ?? 0+"<br/>" ?? "")
      }
      else{
        items = JSON.parse(cartItemsTemp)?.cartItem?.reduce(
          (previousValue, currentValue) => {
            return (
              previousValue +""+
                ( currentValue?.serviceTitle +" - "+ (currentValue?.servicePrice?.filter(
                  e =>
                    e.currencyType?.toString()?.toUpperCase() ==
                    JSON.parse(cartItemsTemp)?.currencyMode
                )[0]?.servicePrice ?? 0).toString()+(currentValue?.servicePrice?.filter(
                  e =>
                    e.currencyType?.toString()?.toUpperCase() ==
                    JSON.parse(cartItemsTemp)?.currencyMode
                )[0]?.currencySymbol ?? "").toString()) +"<br/>"
            )
          },
          ""
        )
        total = JSON.parse(cartItemsTemp)?.cartItem?.reduce(
          (previousValue, currentValue) => {
            return (
              previousValue +
                currentValue?.servicePrice?.filter(
                  e =>
                    e.currencyType?.toString()?.toUpperCase() ==
                    JSON.parse(cartItemsTemp)?.currencyMode
                )[0]?.servicePrice ?? 0
            )
          },
          0
        )
        if(JSON.parse(cartItemsTemp)?.isCreatedBundle){
          total = parseFloat(total) - parseFloat(discountCalcualtor())
        }
      }
    }
    if(items.endsWith(",")){
      items = items.slice(0,-1)
    }
    options  = {
      key: `${process.env.RAZORPAY_KEY}`,
      amount: (parseFloat(total)>0?parseFloat(total):parseFloat(0))*100,
      currency: currencyModeTemp,
      name: localStorage.getItem("firstName"),
      description: items,
      image: sticky_logo,
        handler: async (res) => {
        if(res.razorpay_payment_id){
          await axios.post(`${process.env.RAZORPAY_PAYMENT_API}`, {
            url: `${process.env.RAZORPAY_PAYMENT_BASE_API}/${res.razorpay_payment_id}`
          })
          .then(async function (response) {
            if(response.data){
              try {
                await createPaymentInfo({
                  variables: {
                    email: response.data.email,
                    phoneNumber:response.data.contact,
                    transactionAmount:response.data.amount/100,
                    transactionCurrency:response.data.currency,
                    transactionStatus:response.data.status,
                    razorpayPaymentID:response.data.id,
                    razorpayOrderID:response.data.order_id,
                    userId:localStorage.getItem("userId"),
                    items:response.data?.description ??""
                  },
                }).then(response => {
                  console.log("response", response.data)
                  navigate('/pricing/confirmation/success')
                })
              } catch (error) {
                console.log("error", error)
                navigate('/pricing/confirmation/failure')
              }
            }
            else{
              navigate('/pricing/confirmation/failure')
            }
          })
          .catch(function (error) {
            navigate('/pricing/confirmation/failure')
            console.log(error)
          });
        }
      },
      theme: {
        color: "#3399cc",
      },
    };
    if(parseFloat(total) >0){
      const rzpay = new Razorpay(options);
      rzpay.on("payment.failed",async function (response) {
        var payment_id = response?.error?.metadata?.payment_id ?? null
        rzpay.open(false);
        if(payment_id != null){
          await axios.post(`${process.env.RAZORPAY_PAYMENT_API}`, {
            url: `${process.env.RAZORPAY_PAYMENT_BASE_API}/${payment_id}`
          })
          .then(async function (response) {
            if(response.data){
              try {
                await createPaymentInfo({
                  variables: {
                    email: response.data.email,
                    phoneNumber:response.data.contact,
                    transactionAmount:response.data.amount/100,
                    transactionCurrency:response.data.currency,
                    transactionStatus:response.data.status,
                    razorpayPaymentID:response.data.id,
                    razorpayOrderID:response.data.order_id,
                    userId:localStorage.getItem("userId"),
                    items:response.data?.description ??""
                  },
                }).then(response => {
                  navigate('/pricing/confirmation/failure')
                })
              } catch (error) {
                console.log("error", error)
                navigate('/pricing/confirmation/failure')
              }
            }
            else{
              navigate('/pricing/confirmation/failure')
            }
          })
          .catch(function (error) {
            
            navigate('/pricing/confirmation/failure')
            console.log(error)
          });
        }
        else{
          navigate('/pricing/confirmation/failure');
        }
      });
      rzpay.open();
    }
    else{
      setErrorMessage("Order Amount is zero")
    }
  }, [Razorpay]);

  const loginCheck = e => {
    if (localStorage.getItem("userId")) {
      handlePayment()
    } else {
      e.preventDefault()
      setOpen(true)
      setLogin(true)
      setSuccessMessage("")
      setErrorMessage("")
      setphoneError("")
      localStorage.setItem("lastPage", "pricing-summary")
      return false
    }
  }

  return (
    <Layout setTitle="Pricing Summary">
      <Login
        open={open}
        setOpen={setOpen}
        login={login}
        setLogin={setLogin}
        setUsername={setUsername}
        setUserID={setUserID}
        forgotPassword={forgotPassword}
        setForgotPassword={setForgotPassword}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        successMessage={successMessage}
        setSuccessMessage={setSuccessMessage}
        phoneError={phoneError}
        setphoneError={setphoneError}
      />
      <div className="main-content">
        {summaryData && (
          <div className="summary-section pricingsection">
            <div className="container pricing-section">
              <div className="price-section">
                <div className="pricing-container">
                  <div className="title blue summary-title">
                    {summaryData.summaryPageTitle}
                  </div>
                  <div className="summary-description pricing-description">
                    {summaryData.summaryPageDescription}
                  </div>
                </div>
                <div className="summary-image pricing-image image">
                    <img
                      src={
                        summaryData?.summaryPageImage?.url
                          ? process.env.BACKEND_URL +
                            summaryData?.summaryPageImage?.url
                          : ""
                      }
                      alt="imageSection"
                    />
                </div>
              </div>
            </div>
            {!cartListItem?.isBundleProducts &&
              cartListItem?.cartItem?.length > 0 && (
                <div className="summary-cartItems">
                  <div className="container">
                  {cartListItem?.cartItem?.map((item, index) => (
                    <div className="summary-sections" key={index}>
                      <div className="summary-s-details">
                        <div className="summary-item blue">{item?.serviceTitle}</div>
                        <div className="item-price">
                          {currencyPrinter(item?.servicePrice)}
                        </div>
                      </div>
                    </div>
                  ))}
                  </div>
                </div>
              )}
            {cartListItem?.isBundleProducts && (
              <div className="summary-cartItems container">
                <div className="summary-sections">
                  <div className="summary-s-details">
                    <div className="summary-item blue">
                      {cartListItem?.cartItem?.bundleName}
                    </div>
                    <div className="item-price">
                      {currencyPrinter(cartListItem?.cartItem?.bundlePricing)}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {cartListItem?.isCreatedBundle && (
              <>
                {parseFloat(summaryData.bundleDiscountPrice) > 0?
                  <>
                    {parseFloat(discountCalcualtor()) > 0?
                      <div className="discount-cartItems container">
                        <div className="summary-sections">
                          <div className="summary-s-details">
                            <div className="summary-item blue">
                              Discounts
                            </div>
                            <div className="item-price">
                              {parseFloat(discountCalcualtor())}{currencySymbol}
                            </div>
                          </div>
                        </div>
                      </div>
                    :null}
                  </>
                :null}
              </>
            )}
            {cartListItem && (
              <div className="container">
                <div className="summary-sections total">
                  <div className="summary-s-details">
                  <div className="summary-item">BUNDLE TOTAL</div>
                  <div className="item-price">
                    {cartListItem?.isBundleProducts
                      ? currencyPrinter(cartListItem?.cartItem?.bundlePricing)
                      : bundleTotalCalculator()}
                  </div>
                  </div>
                </div>
              </div>
            )}

            {errorMessage? <div className="error-message text-center">{errorMessage}</div>:null}
            {cartListItem &&  (
              <div className="container">
                <div className="p-btn">
                  <div className="cta-primary">
                    <div
                      className="proceedButton"
                      onClick={e => {
                        loginCheck(e)
                      }}
                    >
                      Proceed to Checkout
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="summary-footer">
              <div className="redirect-text blue">
                {summaryData?.summeryFooterContent}
              </div>
            </div>
          </div>
        )}
      </div>
    </Layout>
  )
}

export default Summary
